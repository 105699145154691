@use '../config/' as *;
.btn {
  &:not(.btn-outline) {
    @extend %btn-fill;
  }
  &-outline {
    @extend %btn-outline;
  }
}
.waf-component {
  &.waf-static {
    @extend %p-0;
  }
}
.waf-static {
  .waf-body {
    @extend %mt-12;
    @extend %mb-20;
  }
  .card {
    &-item:not(:last-child) {
      @extend %mb-8;
    }
    &-content:not(:last-child) {
      @extend %mb-10;
    }
  }
  .label {
    font: 700 1.6rem/1.5 $font-primary;
    @extend %mb-4;
  }
  .value {
    word-wrap: break-word;
    @extend %body-m;
    + .value {
      @extend %mt-6;
    }
    + .label {
      @extend %mt-4;
    }
    .text {
      @extend %body-m;
    }
  }
  .value-zero {
    @extend %body-m;
    + .value {
      @extend %mt-0;
    }
  }
  .link {
    text-decoration: underline;
    @extend %primary-50;
  }
  .action-group {
    @extend %flex-column;
    @extend %gap-4;
  }
  input,
  textarea {
    border: .1rem solid var(--neutral-40);
    @include placeholder(var(--neutral-40));
    @extend %w-100;
    @extend %px-4;
    @extend %border-radius-xs;
    @extend %neutral-100;
    &:focus {
      border-color: var(--neutral-80);
      @extend %neutral-70;
    }
  }
  input {
    height: 4rem;
    @extend %flex-n-c;
  }
  textarea {
    @extend %py-3;
  }
  .form {
    &-element {
      &:not(:last-child) {
        @extend %mb-8;
      }
    }
    &-label {
      @extend %mb-2;
      @extend %d-block;
      @extend %text-m;
      @extend %uppercase;
    }
  }
  .error-span {
    @extend %mt-2;
    @extend %flex-n-c;
    @extend %gap-1;
    @extend %neutral-70;
    @extend %text-s;
    &::before {
      @include icon(info, 16);
    }
  }
  .border-bottom {
    border-bottom: .1rem solid var(--neutral-20);
    @extend %pb-3;
  }
  .option-list-item {
    @extend %flex;
    @extend %body-l;
    &::before {
      content: "";
      width: .5rem;
      height: .5rem;
      margin-top: 1rem;
      flex-shrink: 0;
      @extend %circle-radius;
      @extend %mx-3;
      @extend %bg-neutral-100;
    }
  }
  ul {
    list-style-type: disc;
  }
  li {
    @extend %mb-2;
  }
  ol {
    list-style-type: decimal;
  }
  :is(ul, ol) {
    @extend %pl-4;
    @extend %mb-2;
  }
  // a {
  //   @extend %primary-50;
  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }
  strong,
  em {
    font-weight: 700;
    @extend %neutral-100;
  }
  iframe,
  video {
    min-height: 17.5rem;
    @extend %w-100;
    @extend %mb-6;
    @extend %border-radius-s;
  }
}
.waf-common-static {
  margin-bottom: 0;
  padding-block: var(--space-12) var(--space-20);
  margin-inline: var(--space-2-neg);
  @extend %bg-neutral-5;
  .card {
    &-section {
      padding: var(--space-10) var(--container-white-space);
      @extend %border-radius-m;
      @extend %bg-neutral-0;
      &:not(:first-child) {
        @extend %mt-20;
      }
    }
    &-head {
      @extend %mb-8;
    }
    &-label {
      word-wrap: break-word;
      letter-spacing: -.05rem;
      @extend %title-40;
    }
    &-content {
      .card-action {
        @extend %mt-10;
      }
    }
    &-img {
      @extend %border-radius-s;
    }
  }
  .layout-wrapper {
    @include large-wrapper();
  }
}
@include mq(col-tablet) {
  .waf-static {
    .waf-body {
      margin-top: 8rem;
    }
    .card {
      &-item:not(:last-child) {
        margin-bottom: var(--space-20);
      }
      &-content:not(:last-child) {
        margin-bottom: var(--space-16);
      }
    }
    .label {
      margin-bottom: var(--space-1);
    }
    .value,
    .value-zero {
      font-size: 1.6rem;
    }
    .action-group {
      flex-direction: row;
      gap: var(--space-6);
    }
  }
  .waf-common-static {
    padding-block: var(--space-20);
    .card {
      &-section {
        padding-block: var(--space-20);
        border-radius: var(--border-radius-l);
        &:not(:first-child) {
          margin-top: var(--space-18);
        }
      }
      &-item {
        display: flex;
        gap: var(--space-6);
        // &:has(.accordion-item) {
        //   .card-label {
        //     margin-top: var(--space-5);
        //   }
        // }
      }
      &-head {
        width: 31%;
        // padding-block: var(--space-4);
        margin-bottom: 0;
      }
      &-body {
        width: calc(69% - var(--space-6));
        padding-block: var(--space-6);
        flex-grow: 1;
      }
    }
    &.waf-faq {
      .card-body {
        padding-block: 0;
      }
    }
  }
}