@use "../config/" as *;
:root {
    --card-height: 47rem;
}
.waf-component {
    &.waf-social {
        margin-block: 8rem;
    }
}
.waf-social {
    .waf {
        &-head {
            height: var(--card-height);
            @extend %relative;
            &::before {
                content: '';
                background: url('/static-assets/images/cssimages/pattern/social-wall-bg-m.png?v=#{$image-version}') no-repeat;
                background-size: 100% 100%;
                @extend %w-100;
                @extend %h-100;
                @extend %d-block;
                @extend %position-t-l;
                @extend %zindex-pattern;
            }
        }
        &-body {
            margin-top: calc(-1 * var(--card-height)/2);
        }
    }
    .head {
        &-wrap {
            @extend %p-10;
            @extend %text-center;
        }
        &-tab {
            @extend %mt-10;
            @extend %flex-c-c;
            .btn-site {
                width: max-content;
                @extend %btn-fill-dark-icon;
                .btn-text {
                    font-size: inherit;
                }
            }
        }
    }
    .title {
        @extend %neutral-0;
        @extend %title-40;
    }
    .card {
        &-list {
            @include card-count(1.2, var(--space-4));
            @extend %mr-4-neg;
            @extend %px-4;
        }
        &-item {
            aspect-ratio: 2/3;
            width: 28rem;
            border-radius: 16px;
            @extend %relative;
        }
        &-wrap,
        &-thumbnail {
            @extend %h-100;
            @extend %relative;
        }
        &-thumbnail {
            .img-box,
            img {
                @extend %h-100;
            }
            .img-box {
                border-radius: 1.6rem;
                @extend %relative;
                &::after {
                    content: '';
                    pointer-events: none;
                    background: linear-gradient(0deg, hsl(var(--hsl-neutral-100)/0.3) 0%, hsl(var(--hsl-neutral-100)/0.2) 100%), linear-gradient(0deg, hsl(var(--hsl-neutral-100)/0.5) 0%, hsl(var(--hsl-neutral-100)/.2) 100%);
                    @extend %w-100;
                    @extend %h-100;
                    @extend %position-t-l;
                }
            }
        }
        &-content {
            position: absolute;
            bottom: 0;
            @extend %neutral-0;
            @extend %w-100;
            @extend %p-6;
        }
        &-logo {
            width: 2.4rem;
            height: 2.4rem;
            aspect-ratio: 1/1;
            @include position(null, 2.4rem, 3rem);
            &::after {
                @include icon(instagram, 24);
            }
        }
    }
    .reaction-section {
        @extend %flex;
        @extend %gap-4;
        .item {
            @extend %gap-2;
            @extend %flex-n-c;
        }
    }
    .title {
        width: calc(100% - 2.4rem);
        a {
            @include truncate-text(2);
            @extend %neutral-0;
            @extend %body-lb;
        }
    }
    .like {
        &::before {
            @include icon(heart);
        }
    }
    .comment {
        &:before {
            @include icon(comment);
        }
    }
    .item-type-icon {
        width: 6.4rem;
        height: 6.4rem;
        @include position-combo(center);
        &::before {
            @include icon(play, 32);
            @include bg(neutral-0, 2);
            @extend %flex-c-c;
            @extend %mb-2;
            @extend %border-radius-s;
            @extend %w-100;
            @extend %h-100;
            @extend %neutral-0;
        }
    }
}
@include mq(col-tablet) {
    :root {
        --card-height: 56rem;
    }
    .waf-social {
        .waf {
            &-head {
                &::before {
                    background: url(/static-assets/images/cssimages/pattern/social-wall-bg-d.png?v=#{$image-version}) no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
        .head-wrap {
            padding-top: 8rem;
        }
        .card-list {
            margin: 0;
            padding-inline: 4.4rem;
            @include card-count(2.3, var(--space-6));
        }
    }
}
@include mq(col-lg) {
    .waf-social .card-list {
        @include card-count(3, var(--space-6));
    }
}
@include mq(col-desktop) {
    .waf-social .card-list {
        @include card-count(4, var(--space-6));
    }
}