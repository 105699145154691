@use './config/' as *;
@forward "./main-scss/";
@forward './events/fourstar-shoswcase';
@forward './events/event-homepage-static';
@forward './si-social/social-wall';
@forward './module/static/faq';
@forward './module/static';
@forward './listing/listing-common';
@forward './events/eventstrip';
.waf-static {
    .waf-body {
        @extend %my-0;
    }
}
.waf-for-athletes .waf-body {
    margin-block: var(--space-12) var(--space-20);
}
.waf-event-home {
    > .layout-wrapper {
        max-width: 100%;
    }
}
.waf-modal-event {
    &:not(.active) {
        @extend %d-none;
    }
    .modal {
        &-window {
            @include bg(pure-black-0, 6);
        }
        &-wrapper {
            width: 90%;
            margin: auto;
            border-radius: 1rem;
            min-height: 30rem;
            border: .1rem solid var(--neutral-20);
            @extend %flex-c-c;
            @extend %bg-neutral-0;
            @extend %text-center;
            @extend %px-4;
            @extend %py-9;
            @extend %relative;
            .btn-close {
                @include position(-3rem, 0rem);
                .btn-text {
                    @extend %font-0;
                }
            }
        }
        &-image {
            max-width: 17rem;
            @extend %mx-auto;
        }
        &-desc {
            line-height: 1.3;
            @extend %font-24-pb;
            @extend %my-9;
        }
        &-cta {
            .btn-fill {
                width: max-content;
                @extend %flex-c-c;
                @extend %mx-auto;
                @extend %btn-fill;
                @extend %gap-1;
                &::after {
                    line-height: 1;
                    @include icon(arrow-right, 16);
                }
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-static {
        .waf-body {
            margin-block: 0;
        }
    }
    .waf-modal-event {
        .modal {
            &-wrapper {
                width: 48rem;
            }
            &-image {
                max-width: 19rem;
            }
        }
    }
    .waf-for-athletes {
        .waf-body {
            margin-block: var(--space-20);
        }
        .card-body {
            padding-block: 0;
        }
    }
}