@use "../config" as *;
.waf-component {
    &.waf-montreal,
    &.waf-champion-video {
        @extend %py-10;
        @extend %mb-0;
    }
}
.waf-component {
    &.waf-about-venue {
        @extend %mb-21;
        @extend %py-0;
    }
}
.waf-montreal,
.waf-champion-video,
.waf-about-venue {
    .embed-responsive {
        @extend %border-radius-s;
    }
}
.waf-montreal,
.waf-about-venue {
    .waf-head {
        .title {
            -webkit-line-clamp: unset;
        }
    }
    .card {
        &-list {
            margin-right: calc(-1 * var(--container-white-space));
            @include card-count(1.1, var(--space-6), nowrap);
        }
        &-item {
            &:not(:last-child) {
                margin-bottom: var(--space-0);
            }
        }
    }
}
.waf-montreal,
.waf-champion-video {
    .waf-head {
        @extend %mb-8;
    }
    .title {
        @extend %title-40;
    }
    .sub-title {
        @extend %d-block;
        @extend %title-50;
        @extend %mt-2;
    }
}
.waf-montreal {
    @extend %event-card-big;
    .waf-head {
        .title {
            color: inherit;
        }
    }
    .text {
        font-weight: 700;
        color: var(--neutral-50);
    }
    .content-description {
        @extend %mb-10;
    }
}
.waf-champion-video {
    .sub-title {
        @extend %neutral-70;
    }
}
.waf-row-event-video {
    margin-bottom: -20%;
    @extend %relative;
    @extend %zindex-overlay-icon;
}
.waf-about-venue {
    margin-inline: var(--space-2-neg);
    @extend %mb-21;
    @extend %event-card-small;
    &.waf-about-venue-event {
        .waf-head {
            min-height: 30rem;
        }
    }
    .waf-head,
    .waf-body,
    .waf-footer {
        padding-inline: var(--container-white-space);
    }
    .layout-wrapper {
        max-width: 100%;
    }
    .embed-responsive-item {
        aspect-ratio: 7/5;
        min-height: 24rem;
        @extend %w-100;
        @extend %border-radius-s;
    }
    .sub-title {
        @extend %relative;
        @extend %neutral-20;
        @extend %zindex-overlay-icon;
    }
    .waf-head {
        max-height: 83rem;
        min-height: 47rem;
        isolation: isolate;
        border-radius: var(--full-radius) var(--full-radius) 0 0;
        @include bgImg('cssimages/pattern/head-to-head-bg-web.png', null, 100% 100%);
        @extend %relative;
        @extend %flex-column-c-n;
        &::before,
        &::after {
            content: "";
            pointer-events: none;
            @extend %position-t-l;
            @extend %w-100;
            @extend %h-100;
        }
        &::before {
            background: linear-gradient(180deg, hsl(var(--hsl-neutral-100)/0) 0%, hsl(var(--hsl-neutral-100)/0.99) 69.25%, hsl(var(--hsl-neutral-100)/1) 100%);
        }
        &::after {
            background: linear-gradient(180deg, hsl(var(--hsl-neutral-100)/0) 0%, var(--neutral-100) 87.5%);
        }
        .title {
            @include title-with-icon(shape, map-pin, 20, neutral-0);
            @extend %neutral-5;
            @extend %title-40;
            @extend %mb-2;
            @extend %zindex-overlay-icon;
        }
    }
    .waf-body {
        padding-bottom: 30rem;
        @extend %mt-20-neg;
        @extend %relative;
        &::before {
            content: "";
            @include bgImg('cssimages/pattern/venue-bg-mob.png', null, 100% 100%);
            @extend %position-t-l;
            @extend %w-100;
            @extend %h-100;
        }
    }
    .content-wrapper {
        @extend %flex-column-n-n;
        @extend %gap-10;
    }
    .content-description {
        order: 1;
        @extend %neutral-0;
        .sub-title {
            @extend %body-lb;
            @extend %neutral-0;
        }
    }
    .content-media {
        order: 2;
        @extend %zindex-overlay-icon;
        img {
            aspect-ratio: 7/5;
            min-height: 24rem;
            @extend %w-100;
            @extend %border-radius-s;
        }
    }
    .content-list {
        list-style: disc;
        @extend %pl-5;
        @extend %relative;
        @extend %zindex-overlay-icon;
        @extend %mt-6;
        .text {
            display: list-item;
            @extend %body-l;
        }
        .text:not(:last-of-type) {
            @extend %mb-4;
        }
    }
    .card-list {
        @extend %mt-10;
    }
    .waf-footer {
        margin-top: -25rem;
        @extend %relative;
    }
    .waf-info-cards {
        > .title {
            @extend %neutral-0;
            @extend %relative;
        }
    }
}
.waf-useful-info {
    margin-inline: var(--space-2-neg);
    margin-top: var(--space-16);
    padding-block: var(--space-0);
    @extend %event-card-small;
    .layout-wrapper {
        max-width: 100%;
    }
    .waf-info-cards {
        isolation: isolate;
        border-radius: var(--space-5);
        @include bgImg('cssimages/pattern/venue-bg-mob.png', center, cover);
        @extend %py-8;
        @extend %px-3;
        .title {
            @extend %neutral-5;
            @extend %title-40;
            @extend %mb-2;
        }
    }
    .card {
        &-list {
            @extend %mt-14;
        }
        &-item {
            // padding: var(--space-4) var(--space-3);
            min-height: 7.4rem;
        }
        &-content {
            .title {
                margin-bottom: var(--space-0);
                @extend %primary-50;
            }
        }
    }
}
.waf-event-home {
    &:has(.waf-row-event-video) {
        .waf-useful-info {
            margin-top: var(--space-0);
        }
        .waf-info-cards {
            padding-top: var(--space-24);
        }
    }
    &:not(:has(.waf-useful-info)) {
        .waf-row-event-video {
            margin-bottom: 0%;
        }
    }
}
.waf-map-document {
    @extend %event-card-small;
    &.waf-component {
        @extend %p-10-0;
    }
    &:has(.waf-footer) {
        .waf-body {
            @extend %mb-14;
        }
    }
    .waf-footer {
        > .title {
            @extend %title-40;
            @extend %neutral-100;
            @extend %mb-8;
        }
    }
    .waf-head {
        @extend %mb-12;
        // @extend %d-none;
        .title {
            @extend %font-24-pb;
            @extend %neutral-100;
        }
    }
    .event-map {
        height: 15rem;
        border-radius: var(--space-4);
    }
    .card {
        &-list {}
        &-item {
            &::before {
                opacity: 0.3;
            }
        }
    }
}
.waf-faq {
    border-radius: 4rem 4rem 0 0;
    .card-section {
        padding: var(--space-0);
        @extend %bg-transparent;
    }
}
.waf-faq {
    &.waf-static {
        .layout-wrapper {
            @include default-wrapper();
        }
    }
}
@include mq(col-tablet) {
    .waf-montreal,
    .waf-about-venue {
        .card-list {
            flex-wrap: nowrap;
        }
    }
    .waf-montreal {
        .card {
            &-list {
                margin-right: unset;
                @include card-count(2, var(--space-6), nowrap);
            }
            &-item {
                min-width: 30.2rem;
            }
        }
    }
    .waf-row-event-video {
        margin-bottom: -15%;
    }
    .waf-about-venue {
        &.waf-about-venue-event {
            .waf-head {
                min-height: 44rem;
            }
        }
        .card-item {
            max-width: 30rem;
        }
    }
    .waf-event-home {
        &:has(.waf-row-event-video) {
            .waf-useful-info {
                margin-top: var(--space-0);
            }
            .waf-info-cards {
                padding-top: calc(var(--space-24) + var(--space-12));
            }
        }
    }
    .waf-map-document {
        &.waf-component {
            padding: 9.6rem 0;
        }
        .card-list {
            flex-basis: 70%;
            @include card-count(3, var(--space-4))
        }
        .event-map {
            height: 56rem;
        }
        .waf-footer {
            display: flex;
        }
        .title {
            flex-basis: 30%;
        }
    }
}
@include mq(col-lg) {
    .waf-component {
        &.waf-montreal,
        &.waf-champion-video {
            padding-block: var(--space-20);
        }
        &.waf-about-venue {
            margin-bottom: var(--space-17);
        }
    }
    .waf-montreal,
    .waf-champion-video,
    .waf-about-venue {
        .waf-head {
            margin-bottom: var(--space-10);
        }
        .embed-responsive {
            border-radius: var(--space-4);
        }
        .card {
            &-list {
                margin-right: unset;
            }
        }
    }
    .waf-montreal,
    .waf-champion-video {
        .waf-head .title {
            font-size: 3.2rem;
        }
        .sub-title {
            font-size: 2.4rem;
        }
    }
    .waf-champion-video {
        .waf-head {
            margin-bottom: var(--space-6);
        }
    }
    .waf-montreal {
        .text {
            font-size: 1.6rem;
        }
        .content-wrapper {
            gap: 7.5%;
            @include flex-config(flex);
        }
        .content-description {
            margin-bottom: var(--space-0);
            width: 42.5%;
        }
    }
    .waf-about-venue {
        &.waf-about-venue-event {
            .waf-head {
                min-height: 54rem;
                height: unset;
                padding-bottom: 12rem;
            }
        }
        .sub-title {
            width: 72%;
            font-size: 1.6rem;
        }
        .card-item {
            max-width: unset;
        }
        .embed-responsive-item {
            aspect-ratio: unset;
            min-height: auto;
            height: 100%;
        }
        .waf-head {
            justify-content: flex-end;
            height: 83rem;
            padding-bottom: 19.2rem;
            border-radius: var(--space-10);
            .title {
                margin-bottom: var(--space-4);
                font-size: 4rem;
                &::before {
                    margin-bottom: var(--space-4);
                }
            }
        }
        .waf-body {
            &::before {
                content: "";
                @include bgImg('cssimages/pattern/venue-bg-web.png', null, 100% 100%);
            }
        }
        .content-wrapper {
            flex-direction: row;
            gap: 5%;
        }
        .content-description {
            order: 2;
        }
        .content-media {
            order: 1;
            border-radius: var(--border-radius-m);
            width: 55%;
            flex-shrink: 0;
            img {
                aspect-ratio: unset;
                min-height: auto;
                height: 100%;
            }
        }
        .card-list {
            @include card-count(4, var(--space-6), wrap);
        }
        .waf-footer {
            .card-content {
                .title {
                    font-size: 2rem;
                }
            }
        }
        .waf-info-cards {
            > .title {
                font-size: 3.2rem;
            }
        }
    }
    .waf-row-event-video {
        margin-bottom: -12%;
    }
    .waf-useful-info {
        .waf-info-cards {
            // padding: var(--space-20) var(--space-16);
            @include bgImg('cssimages/pattern/venue-bg-web.png', center, cover);
            .title {
                font-size: 2.6rem;
            }
            .card-list,
            .title {
                max-width: var(--container-max-width);
                margin-inline: auto;
            }
        }
        .card {
            &-item {
                min-height: 9.6rem;
            }
        }
    }
    .waf-event-home {
        &:has(.waf-row-event-video) {
            .waf-info-cards {
                padding-top: calc(var(--space-24) * 2);
            }
        }
    }
    .wtc-event-page {
        .waf-faq.waf-common-static {
            .waf-body .card-label {
                font-size: 3.2rem;
                margin-block: var(--space-0);
                line-height: 1.2;
            }
        }
    }
}
@media (min-width: 1600px) {
    .waf-about-venue {
        .waf-head,
        .waf-body,
        .waf-footer {
            padding-inline: calc((var(--window-inner-width) - var(--container-max-width) - var(--container-white-space)) / 1.65);
        }
    }
    .waf-event-home {
        &:has(.waf-row-event-video) {
            .waf-info-cards {
                padding-top: calc(var(--space-24) * 2.5);
            }
        }
    }
}